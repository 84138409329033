<template>
  <div class="card border-1 mb-2">
    <div class="card-body pt-2 pb-2 px-3">
      <div class="row font-size-lg">
        <p class="col-8 text-left mb-0"><strong>{{ name }}</strong></p>
        <p class="col-4 text-right text-primary mb-0"><strong>${{ price.toLocaleString('es-CL') }}</strong></p>
      </div>
      <div class="row">
        <p class="col small text-left mb-0">{{ include }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TariffCard',
  props: {
    name: String,
    include: String,
    price: Number
  },
}
</script>

<style scoped>
</style>
